.swipe {
    overflow: hidden;
    visibility: visible;
    position: relative;
    margin: 0 auto;
}

.swipe {
    -moz-perspective: 1600px;
    -o-perspective: 1600px;
    -ms-perspective: 1600px;
    transform: rotateX(0deg) rotateY(0deg) translate3d(0, 0, 0);
}

.webBannerBox {
    margin: 0 auto;
    position: relative;
}

/* Swipe 2 required styles */
.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;

    margin: 0 auto;
}

.swipe img {
    width: 100%;
}

.hiddenDefaultBanner .defaultBanner {
    background: none;
}

.swipe .bannerImageDiv {
    background-repeat: no-repeat;
    height: 100%;
    background-position: 50% 50%;
}

.swipe .systemtBanner {
    background-repeat: no-repeat;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

/*2016-11-29修改contain为cover,因为图片会出现白边*/
/*.swipe .customBanner{background-size: cover;}*/
/*2018-11-12修改cover为100% 100%,为了让图片显示完全，但是图片比例会变*/
.swipe .customBanner {
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
}

.bannerHidden {
    display: none;
}

.bannerSwipeContainer {
    overflow: hidden;
    position: relative;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    perspective: 500;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
}

.bannerSwipeContainer img {
    display: inline-block;
    width: 100%;
    /*height: 100%;*/
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.bannerSwipeContainer > div {
    float: left;
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;
}

.bannerSwipeContainer a[href='javascript:;'] {
    cursor: default;
}

.bannerSwipeContainer .bannerDescription {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1.3rem;
    background-color: rgba(0, 0, 0, 0.45);
    padding-left: 10px;
    z-index: 999;
}

.bannerSwipeContainer .bannerDescription .description {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    color: #fff;
    line-height: 1.3rem;
    width: 70%;
}

.billboard > div {
    clear: both;
    position: absolute;
}

.webBannerBox .bannerBullet {
    position: absolute;
    height: 0.9rem;
    bottom: 0px;
    width: 100%;
}

.webBannerBox .bannerBullet2 {
    position: absolute;
    height: 1.3rem;
    bottom: 0px;
    width: 30%;
    right: 0;
}

.webBannerBox .bannerBullet .bullets {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.webBannerBox .bannerBullet2 .bullets {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.3rem;
    position: absolute;
    right: 0.3rem;
}

.webBannerBox .bullets li {
    display: inline-block;
    width: 0.35rem;
    height: 0.35rem;
    border-radius: 100%;
    background: #999;
    margin: 0 0.1rem;
    cursor: pointer;
    background: rgba(170, 170, 170, 0.5);
    vertical-align: middle;
}

.webBannerBox .bullets li.on {
    background: #fff;
}

.defaultBanner {
    background-image: url(/image/banners/N2004.jpg?v=201803121311);
    /*    background-position: 50% 50%;
    */
}
