.newsDetail_hide--img {
    max-width: 15.15rem;
    height: 8.34rem;
    width: 100%;
    margin: 4.56rem auto 1.55rem;
    background: url(/image/v2/lookTipImg.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.newsDetail_hide--text {
    text-align: center;
    font-size: 0.54rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-bottom: 50px;
}
